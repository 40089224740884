<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="mainList_operation_search">
          <span
            >部门：
            <DeptCascader
              :placeholder="'请选择部门'"
              :deptList="deptList"
              v-model="parameter.departmentId"
            />
          </span>
          <span
            >年度：
            <el-select
              v-model="parameter.year"
              clearable
              @change="dataUpdate"
              placeholder="请选择年度"
              style="width: 150px; margin-right: 15px"
              class="ipt_width"
            >
              <el-option
                v-for="item in YearList"
                :label="item.dictName"
                :value="item.dictVal"
                :key="item.dictVal"
              ></el-option>
            </el-select>
          </span>
          <span
            >项目名称：
            <el-select
              v-model="parameter.projectId"
              @change="dataUpdate"
              clearable
              filterable
              class="ipt_width"
              placeholder="请输入或选择项目名"
              style="width: 150px; margin-right: 15px"
            >
              <el-option
                v-for="item in projectList"
                :key="item.id"
                :label="item.projectName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </span>

          <el-button type="primary" icon="el-icon-search" @click="pageChangeHandler(1)"
            >搜索</el-button
          >
          <el-button type="warning" icon="el-icon-refresh" @click="handleReset">清空</el-button>
        </div>
      </div>
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-table ref="multipleTable" border :data="tableData" v-loading="loading">
            <el-table-column type="index" align="center" label="序号" width="50"> </el-table-column>
            <el-table-column align="center" prop="year" sortable min-width="90" label="年度">
              <template slot-scope="scope">
                {{ scope.row.year | dict(dictData.projectYear) }}
              </template>
            </el-table-column>

            <el-table-column align="center" prop="departmentName" label="部门名称">
            </el-table-column>
            <el-table-column
              min-width="320"
              align="center"
              prop="title"
              label="项目名称"
              :show-overflow-tooltip="false"
            ></el-table-column>
            <el-table-column align="center" prop="type" sortable min-width="70" label="类型">
              <template slot-scope="scope">
                {{ scope.row.type | dict(typeList) }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="typeDetail" label="类型详情">
              <template slot-scope="scope">
                {{ scope.row.typeDetail | dict(typeDetailList) }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="produceTime"
              sortable
              min-width="130"
              label="产生时间"
            >
              <template slot-scope="scope">
                {{ scope.row.produceTime | dateFormat }}
              </template>
            </el-table-column>

            <el-table-column
              align="center"
              prop="departmentPerformanceDetail"
              label="部门管理绩效(元)"
            >
              <template slot-scope="scope">
                {{ scope.row.departmentPerformanceDetail | thousands }}
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
            :current-page="parameter.pageNow"
            :page-size="parameter.pageSize"
            prev-text="上一页"
            next-text="下一页"
            layout="total, prev, pager, next, slot, jumper"
            :total="parameter.total"
          >
            <span class="el-pagination__jump e_a_pagination">
              <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
              <span style="padding-top: 1px">条/页</span>
            </span>
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { initTreeData } from '@/util/common'
import { mapState } from 'vuex'
export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    DeptCascader: () => import('@/components/dept/DeptCascader.vue'),
  },
  data() {
    return {
      parameter: {
        pageNow: 1,
        pageSize: 50,
        total: 0,
      },
      pageSize: 0,
      dictData: {
        projectYear: [],
      },
      tableData: [],
      deptList: [],
      loading: false,
      projectList: [],
      YearList: [],
      typeList: [],
      typeDetailList: [],
    }
  },
  watch: {},
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
  },
  filters: {
    thousands(a) {
      if (a == null || a == '' || a == undefined) {
        return 0
      } else {
        a = Number(a).toLocaleString()
        return a
      }
    },

    dateFormat(time3) {
      //将时间戳格式转换成年月日时分秒
      var date = new Date(time3)
      var Y = date.getFullYear() + '-'
      var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
      var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '

      var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
      var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
      var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      var strDate = Y + M + D + h + m + s
      return strDate
    },
  },
  created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    this.getData()
    this.getProject()
    this.$api.dict
      .listSysDictData('JX_COST_TYPE', true)
      .then(res => {
        this.typeList = res.data
      })
      .catch(err => {
        console.log(err)
      })
    this.$api.dict
      .listSysDictData('JX_COST_TYPE_DETAIL', true)
      .then(res => {
        this.typeDetailList = res.data
      })
      .catch(err => {
        console.log(err)
      })
    // this.$api.dict
    //   .listSysDictData("PROJECT_TYPE", true)
    //   .then((res) => {
    //     this.dictData.projectType = res.data;
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    this.$api.dict
      .listSysDictData('PROJECT_YEAR', true)
      .then(res => {
        this.dictData.projectYear = res.data
        this.YearList = initTreeData(res.data)
      })
      .catch(err => {
        console.log(err)
      })

    this.$api.sysDept
      .getTreeDept()
      .then(res => {
        this.deptList = res.data
      })
      .catch(err => {
        console.log(err)
      })
  },
  methods: {
    handleReset() {
      this.parameter.projectId = ''
      this.parameter.departmentId = ''
      this.parameter.year = ''
      this.getData() /** 数据 **/
    },
    getProject() {
      this.$api.project
        .projectList({
          pageSize: 10000,
          pageNow: 1,
        })
        .then(res => {
          this.projectList = res.data ? res.data : []
        })
        .catch(err => {
          console.log(err)
        })
    },
    dataUpdate(row) {
      this.$forceUpdate()
    },
    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.getData()
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getData()
    },
    getData() {
      this.loading = true
      this.$api.performanceManagement
        .departmentPerformanceDetailController(this.parameter)
        .then(res => {
          this.loading = false

          this.tableData = res.data.records

          this.parameter.total = res.data.total
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },

    handlePageSize() {
      if (this.userInfo.pageSizeLog == this.pageSize) {
        return
      }
      if (!this.pageSize) {
        this.pageSize = 1
      }
      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageNow = 1
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.getData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';
</style>
